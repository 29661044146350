<template>
  <div class="brand-container" style="color: #5e72e4;">
    <div class="my-auto " id="logo">
      <svg id="logo-pic" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 226 57.2" style="max-height: 2.5em; cursor: pointer">
        <path class="battery" d="M217.8 21.1h-1.5c-.2 0-.3 0-.5.1v-5c0-5.2-4.2-9.4-9.4-9.4H16.8c-5.2 0-9.4 4.2-9.4 9.4v26.9c0 5.2 4.2 9.4 9.4 9.4h189.7c5.2 0 9.4-4.2 9.4-9.4v-4.9c.1.1.3.1.5.1h1.5c.6 0 1.1-.5 1.1-1.1v-15c-.1-.7-.6-1.1-1.2-1.1z"/>
        <path class="text" fill="white" d="M15.4 44.5V15h13.1l4.7 21.7L38 15h12.9v29.5h-7.6V19.8l-6.2 24.6h-8L23 20.2v24.3h-7.6zM53.9 15H62v23.1h11.5V15h8.1v11.4c0 5.1-.1 8.4-.2 9.8s-.5 2.8-1 4c-.8 1.7-2.1 2.9-3.9 3.5-1.8.6-4.5 1-8.4 1-3.8 0-6.6-.2-8.1-.5-1.6-.4-2.9-1.1-3.9-2.2-.8-.9-1.3-2-1.7-3.5-.4-1.5-.6-3.3-.6-5.5V15zm29.6 29.5l9.2-15.2L83.8 15H93l6.3 10.5 6-10.5h8.9l-8.9 14.6 9.2 14.9h-9.2l-6.8-10.8L92 44.5h-8.5zm34.9 0v-6.6h18.1v-4.8h-6.9c-2.9 0-4.7 0-5.6-.1-.9-.1-1.8-.4-2.7-.8-1.3-.6-2.2-1.6-2.9-2.9-.7-1.4-1-3.1-1-5.1 0-1.8.3-3.4 1-4.8.6-1.4 1.6-2.4 2.7-3.1.8-.5 1.7-.8 2.7-1 1-.2 2.4-.3 4.4-.3h14.4v6.6h-17.1v4.8h8.8c1.8 0 3.2.1 4.2.3 1.1.2 2 .6 2.8 1.1 2.3 1.4 3.4 3.9 3.4 7.3 0 1.8-.3 3.3-.9 4.7-.6 1.3-1.4 2.4-2.6 3.1-.9.6-1.8 1-3 1.2s-2.7.4-4.6.4h-15.2zm26.4 0L155.2 15h11.4l10.5 29.5h-8.2L167 39h-12.4l-2.1 5.5h-7.7zm12-12.1h8l-4-11.8-4 11.8zm21.3 12.1V15h13.2l8.7 23.8V15h7.8v29.5H195l-9.1-23.3v23.3h-7.8z" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "Brand"
}
</script>

<style scoped>
.brand-container {
  height: 80px;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

#logo:hover .battery {
  fill: currentColor;
  cursor: pointer;
}
#logo .battery {
  fill: black;
  transition: fill 250ms;
  cursor: pointer;
}

</style>