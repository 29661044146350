<template>
  <div id="reset" style="padding-top: 80px">
    <a href="https://www.muxsan.com" class="brand">
      <Brand style="color: teal"></Brand>
    </a>
    <div class="container">
      <div class="d-flex justify-content-center" style="margin: 1.5rem 0">
        <h3 style="font-weight: 300">Forgot password</h3>
      </div>
    </div>
    <div class="container" style="max-width: 384px; padding: 0 !important;">
      <b-alert :show="error !== null" variant="danger">
        {{ error }}
      </b-alert>
      <b-alert :show="success" variant="success">
        Email has been successfully sent!
      </b-alert>
    </div>
    <div class="container reset-container">
      <div>
        <form @submit.prevent="reset" ref="resetForm">
          <b-form-group label-for="Username-input" label="Email">
            <b-input-group>
              <b-form-input required
                            v-model="email"
                            id="email-input"
                            ref="email-input"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
          <b-button type="submit" :disabled="sending" variant="success" style="width: 100%">
            <template v-if="sending">Send reset link...</template>
            <template v-else>Send reset link</template>
          </b-button>
        </form>
        <div class="mt-3">
            <router-link to="/" >
              Go back to login
            </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Brand from "@/components/Brand"
import axios from "axios";
import util from "@/mixins/util";

export default {
  name: 'Reset',
  components: {Brand},
  mixins: [util],
  data() {
    return {
      error: null,
      email: '',
      emailState: null,
      sending: false,
      success: false
    }
  },
  methods: {
    /**
     * The normal reset style, eg. using username and password.
     */
    reset() {
      if (!this.checkFormValidity()) {
        return
      }
      this.sending = true
      this.error = null

      axios.post('/passwordReset', {
        email: this.email,
      }).then(() => {
        this.success = true
      }).catch((error) => {
        if (error.response) {
          this.error = error.response.data.message
        } else {
          this.error = 'Could not connect to server. Please try again, or contact an administrator if the issue persists.'
        }
      }).finally(() => {
        this.sending = false
      })
    },
    checkFormValidity() {
      this.checkFieldValidity('emailState', 'email-input')
      return this.$refs.resetForm.checkValidity()
    },
    checkFieldValidity(state, fieldRef) {
      this[state] = this.$refs[fieldRef].checkValidity()
    },
  },
  mounted() {
    if (this.$route.query.error) {
      this.error = this.$route.query.error
      let query = Object.assign({}, this.$route.query);
      delete query.error;
      this.$router.replace({ query });
    }
  }
}
</script>

<style scoped>
.reset-container {
  max-width: 384px;
  border: 1px solid #e5e5e5;
  padding: 1.75em 1.25em;
  border-radius: .375rem;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
}

.brand, .brand:hover, .brand:focus {
  outline: none;
  font-size: 20px;
}
</style>
