<template>
  <div>
    <div class="d-flex justify-content-center mt-5">Redirecting shortly</div>
    <div class="d-flex justify-content-center mt-2">
      <b-spinner variant="secondary" label="Loading..."></b-spinner>
    </div>
  </div>
</template>

<script>
// import axios from 'axios'

export default {
  name: "OAuth2Redirect",
  mounted() {
    const authInfo = this.$route.query;
    if (authInfo.error !== true) {
      setTimeout(() => this.$router.replace({ name: 'login-success' }), 1000)
    }
    // console.log(`${process.env.VUE_APP_API_URL}/code`,authInfo)
    // console.log(authInfo.code)
    // axios.post(`${process.env.VUE_APP_API_URL}/code`, authInfo)
    //     .then(response => {
    //       console.log(response)
    //     })
    //     .catch(err => {
    //       console.log(err)
    //     })
  }
}
</script>

<style scoped>

</style>