<template>
  <div id="login" style="padding-top: 80px">
    <a href="https://www.muxsan.com" class="brand">
      <Brand style="color: teal"></Brand>
    </a>
    <div class="container">
      <div class="d-flex justify-content-center" style="margin: 1.5rem 0">
        <h3 style="font-weight: 300">Selecteer een Service</h3>
      </div>
    </div>
    <div class="container login-container">
      <template v-if="!loading">
        <div v-if="accessibleServices.length > 0">
          <a class="service-container"
             v-for="service of accessibleServices"
             :key="service.name"
             :href="service.href"
          >
            <img class="ml-2" :src="service.iconLink" width="22" height="22" alt="">
            <span class="pl-2">{{ service.name }}</span>
          </a>
        </div>
        <div v-else class="justify-content-center d-flex">
          You don't seem to have access to any services.
        </div>
      </template>
      <template v-else>
        <div class="text-center">
        <b-spinner variant="primary"></b-spinner>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Brand from "@/components/Brand";
import axios from "axios";
import util from "@/mixins/util";

export default {
  name: "Services",
  components: {Brand},
  mixins: [util],
  data() {
    return {
      services: [],
      loading: true
    }
  },
  mounted() {
    this.services = JSON.parse(process.env.VUE_APP_SERVICES)
    const cookie = JSON.parse(atob(this.getCookie(process.env.VUE_APP_AUTH_COOKIE)))
    let options = {headers: {'Authorization': `Bearer ${cookie}`}}
    axios.get(`${process.env.VUE_APP_API_URL}/tokens`, options).then(tokens => {
      options = {headers: {'Authorization': `Bearer ${tokens.data.token}`}}
      axios.get(`${process.env.VUE_APP_API_URL}/users/me`, options).then(response => {
        const isAdmin = response.data.admin
        axios.get(`${process.env.VUE_APP_API_URL}/users/me/services`, options).then(response => {
          for (const accessibleService of response.data) {
            for (const registeredService of this.services) {
              registeredService.hasAccess = (registeredService.id === accessibleService.id || isAdmin);
            }
          }
          this.loading = false
        })
      })
    })
  },
  computed: {
    accessibleServices: function () {
      return this.services.filter(service => service.hasAccess)
    }
  }
}
</script>

<style scoped>
.login-container {
  max-width: 384px;
  border: 1px solid #e5e5e5;
  padding: 1.75em 1.25em;
  border-radius: .375rem;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
}

.brand, .brand:hover, .brand:focus {
  outline: none;
  font-size: 20px;
}

.service-container {
  text-decoration: unset;
  color: #2c3e50;
  width: 100%;
  border-radius: .375rem;
  border: 1px solid #e5e5e5;
  padding: 10px 5px;
  display: flex;
  align-items: center;
  transition: background 200ms ease-in-out;
  margin-bottom: 15px;
}

.service-container:last-of-type {
  margin: 0;
}

.service-container:hover {
  background: #e6e6e6;
}
</style>