import Vue from "vue";
import VueRouter from "vue-router";
import Authenticator from "../Authenticator";
import NotFound from "@/components/error/Error404";
import Login from "@/components/auth/Login";
import OAuth2Redirect from "@/components/auth/OAuth2Redirect";
import Success from "@/components/auth/Success";
import Services from "@/components/auth/Services";
import AcceptInvite from "@/components/auth/AcceptInvite";
import ResetPassword from "@/components/auth/ResetPassword";
import ForgotPassword from "@/components/auth/ForgotPassword";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: NotFound,
    name: "notFound",
  },
  {
    path: "/",
    component: Authenticator,
    redirect: "login",
    children: [
      {
        path: "/login",
        name: "login",
        component: Login,
      },
      {
        path: "/forgot-password",
        name: "forgot-password",
        component: ForgotPassword,
      },
      {
        path: "/reset-password",
        name: "reset-password",
        component: ResetPassword,
      },
      {
        path: "/services",
        name: "services",
        component: Services,
      },
      {
        path: "/login/oauth2/redirection",
        name: "oauth2",
        component: OAuth2Redirect,
      },
      {
        path: "/login/success",
        name: "login-success",
        component: Success,
      },
      {
        path: "/profile/invite",
        name: "invite",
        component: AcceptInvite,
      },
    ],
  },
];

document.title = "Sign in - MUXSAN";

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
