<template>
  <div id="login" style="padding-top: 80px">
    <a href="https://www.muxsan.com" class="brand">
      <Brand style="color: teal"></Brand>
    </a>
    <div class="container">
      <div class="d-flex justify-content-center" style="margin: 1.5rem 0">
        <h3 style="font-weight: 300">Accept Invite</h3>
      </div>
    </div>
    <div class="container" style="max-width: 384px; padding: 0 !important;">
      <b-alert :show="error !== null" variant="danger">
        {{ error }}
      </b-alert>
    </div>
    <div class="container login-container">
      <div>
        <form @submit.prevent="login" ref="loginForm">
          <b-form-group label-for="Fullname-input" label="Enter your full name">
            <b-input-group>
              <b-form-input required
                            v-model="fullName"
                            id="Fullname-input"
                            ref="Fullname-input"
                            autocomplete="name"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
          <b-form-group label-for="Username-input" label="Choose a username"
                        :description="usernamePrefilled ? 'Your username has been prefilled by an admin' : ''">
            <b-input-group>
              <b-form-input required
                            :disabled="usernamePrefilled"
                            :readonly="usernamePrefilled"
                            v-model="username"
                            id="Username-input"
                            ref="Username-input"
              ></b-form-input>
              <template #append>
                <b-input-group-text style="background: white">
                  <b-icon icon="person-fill"></b-icon>
                </b-input-group-text>
              </template>
            </b-input-group>
          </b-form-group>
          <b-form-group label-for="Password-input" label="Choose a password">
            <b-input-group>
              <b-form-input required
                            v-model="password"
                            id="Password-input"
                            ref="password"
                            @input="checkPassword"
                            :type="!showPassword ? 'password' : 'text'"
              ></b-form-input>
              <template #append>
                <b-input-group-text style="background: white">
                  <div @click="showPassword = !showPassword" style="cursor: pointer">
                    <b-icon v-if="showPassword" icon="eye-fill"/>
                    <b-icon v-else icon="eye-slash-fill"/>
                  </div>
                </b-input-group-text>
              </template>
            </b-input-group>
          </b-form-group>
          <ul>
            <li v-bind:class="{ is_valid: containsEightCharacters }">8 Characters</li>
            <li v-bind:class="{ is_valid: containsNumber }">Contains Number</li>
            <li v-bind:class="{ is_valid: containsUppercase }">Contains Uppercase</li>
            <li v-bind:class="{ is_valid: containsSpecialCharacter }">Contains Special Character</li>
          </ul>
          <b-button type="submit" :disabled="!validPassword || sending" variant="success" style="width: 100%">
            <template v-if="sending">Logging in...</template>
            <template v-else>Accept Invite</template>
          </b-button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Brand from "@/components/Brand";
import axios from "axios";
import util from "@/mixins/util";

export default {
name: "AcceptInvite",
  components: {Brand},
  mixins: [util],
  data() {
    return {
      code: '',
      sending: false,
      showPassword: false,
      fullName: '',
      username: '',
      password: '',
      error: null,
      containsEightCharacters: false,
      containsNumber: false,
      containsUppercase: false,
      containsSpecialCharacter: false,
      validPassword: false,
      usernamePrefilled: false
    }
  },
  methods: {
    login() {
      this.sending = true
      axios.post(`/userInvitations/${this.code}`, {
        fullName: this.fullName,
        username: this.username,
        password: this.password
      }).then(response => {
        const cookieName = process.env.VUE_APP_AUTH_COOKIE
        this.setCookie(cookieName, btoa(JSON.stringify(response.data.refreshToken)), 14)
        document.location = `${localStorage.getItem('referer')}`
        this.sending = false
      }).catch(err => {
        this.error = `${err.response.data.message}`
        this.sending = false
      })
    },
    checkPassword() {
      this.password_length = this.password.length;
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
      this.containsEightCharacters = this.password_length > 8;
      this.containsNumber = /\d/.test(this.password);
      this.containsUppercase = /[A-Z]/.test(this.password);
      this.containsSpecialCharacter = format.test(this.password);
      this.validPassword = this.containsEightCharacters === true &&
          this.containsSpecialCharacter === true &&
          this.containsUppercase === true &&
          this.containsNumber === true;
    }
  },
  mounted() {
    document.title = 'Accept Invite - MUXSAN'
    if (
        this.$route.query.code !== null &&
        this.$route.query.code !== undefined &&
        this.$route.query.code.length > 5
    ) {
      this.code = this.$route.query.code
      axios.get(`/userInvitations/${this.code}`).then(response => {
        if (response.data.username !== null) {
          this.username = response.data.username
          this.usernamePrefilled = true
        }
      })
    } else {
      this.$router.replace({ name: 'login' })
    }
  }
}
</script>

<style scoped>
.login-container {
  max-width: 384px;
  border: 1px solid #e5e5e5;
  padding: 1.75em 1.25em;
  border-radius: .375rem;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
}

.brand, .brand:hover, .brand:focus {
  outline: none;
  font-size: 20px;
}

ul {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

li {
  color: #525f7f;
  position: relative;
}

li:before {
  content: "";
  width: 0; height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0; top: 50%;
  display: block;
  transition: all .6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.is_valid { color: rgba(136, 152, 170, 0.8); }
.is_valid:before { width: 100%; }
</style>