<template>
  <main>
    <b-container class="wrapped-container">
      <router-view class="layout-content"></router-view>
      <div class="push"></div>
    </b-container>
    <footer>
      <small class="text-muted">Version: {{ version }} ~ Designed and Developed By
        <a href="https://www.gyrobian.nl">Gyrobian</a>
      </small>
    </footer>
  </main>
</template>

<script>
import {version} from '../package.json'

export default {
  name: 'Authenticator',
  data() {
    return {
      version: version + process.env.VUE_APP_FRONT_END_VERSION_APPEND
    }
  }
}
</script>

<style>
html {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  box-sizing: border-box;
}

footer {
  display: flex !important;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.push {
  margin-top: 40px;
}

main {
  height: 100%;
  margin: 0;
}

.wrapped-container {
  padding-top: .5em;
  min-height: 100vh;
  max-width: 800px;

  /* Equal to height of footer */
  /* But also accounting for potential margin-bottom of last child */
  margin-bottom: -40px;
}

</style>
