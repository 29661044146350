<template>
  <div id="login" style="padding-top: 80px">
    <a href="https://www.muxsan.com" class="brand">
      <Brand style="color: teal"></Brand>
    </a>
    <div class="container">
      <div class="d-flex justify-content-center" style="margin: 1.5rem 0">
        <h3 style="font-weight: 300">Log in bij Muxsan</h3>
      </div>
    </div>
    <div class="container" style="max-width: 384px; padding: 0 !important;">
      <b-alert :show="error !== null" variant="danger">
        {{ error }}
      </b-alert>
    </div>
    <div class="container login-container">
      <div>
        <form @submit.prevent="login" ref="loginForm">
          <b-form-group label-for="Username-input" label="Username">
            <b-input-group>
              <b-form-input required
                            v-model="username"
                            id="Username-input"
                            ref="Username-input"
              ></b-form-input>
              <template #append>
                <b-input-group-text style="background: white">
                  <b-icon icon="person-fill"></b-icon>
                </b-input-group-text>
              </template>
            </b-input-group>
          </b-form-group>
          <b-form-group label-for="Password-input" label="Password">
            <b-input-group>
              <b-form-input required
                            v-model="password"
                            id="Password-input"
                            ref="password"
                            :type="!showPassword ? 'password' : 'text'"
              ></b-form-input>
              <template #append>
                <b-input-group-text style="background: white">
                  <div @click="showPassword = !showPassword" class="clickable">
                    <b-icon v-if="showPassword" icon="eye-fill"/>
                    <b-icon v-else icon="eye-slash-fill"/>
                  </div>
                </b-input-group-text>
              </template>
            </b-input-group>
          </b-form-group>
          <b-form-group label-for="rememberme">
            <b-form-checkbox v-model="rememberMe"
                             id="rememberme"
            >Remember me
            </b-form-checkbox>
          </b-form-group>
          <b-button type="submit" :disabled="sending" variant="success" style="width: 100%">
            <template v-if="sending">Inloggen...</template>
            <template v-else>Inloggen</template>
          </b-button>

          <b-button @click="doGoogleLogin" class="mt-3" variant="light" style="width: 100%; border-color: lightgray !important;">
            <div class="d-flex justify-content-center">
              <svg width="25" height="25" class="oj he z">
                <g fill="none" fill-rule="evenodd">
                  <path
                      d="M20.66 12.7c0-.61-.05-1.19-.15-1.74H12.5v3.28h4.58a3.91 3.91 0 0 1-1.7 2.57v2.13h2.74a8.27 8.27 0 0 0 2.54-6.24z"
                      fill="#4285F4"></path>
                  <path
                      d="M12.5 21a8.1 8.1 0 0 0 5.63-2.06l-2.75-2.13a5.1 5.1 0 0 1-2.88.8 5.06 5.06 0 0 1-4.76-3.5H4.9v2.2A8.5 8.5 0 0 0 12.5 21z"
                      fill="#34A853"></path>
                  <path
                      d="M7.74 14.12a5.11 5.11 0 0 1 0-3.23v-2.2H4.9A8.49 8.49 0 0 0 4 12.5c0 1.37.33 2.67.9 3.82l2.84-2.2z"
                      fill="#FBBC05"></path>
                  <path
                      d="M12.5 7.38a4.6 4.6 0 0 1 3.25 1.27l2.44-2.44A8.17 8.17 0 0 0 12.5 4a8.5 8.5 0 0 0-7.6 4.68l2.84 2.2a5.06 5.06 0 0 1 4.76-3.5z"
                      fill="#EA4335"></path>
                </g>
              </svg>
              <div class="ml-1">Log in with Google</div>
            </div>
          </b-button>
        </form>
        <div class="mt-3">
          <router-link to="forgot-password">
            Forgot password?
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Brand from "@/components/Brand"
import axios from "axios";
import util from "@/mixins/util";

export default {
  name: 'Login',
  components: {Brand},
  mixins: [util],
  data() {
    return {
      error: null,
      username: '',
      usernameState: null,
      password: '',
      passwordState: null,
      sending: false,
      rememberMe: false,
      showPassword: false
    }
  },
  methods: {
    /**
     * The normal login style, eg. using username and password.
     */
    login() {
      if (!this.checkFormValidity()) {
        return
      }
      const cookieName = process.env.VUE_APP_AUTH_COOKIE
      this.sending = true
      this.error = null

      axios.post('/tokens/login', {
        username: this.username,
        password: this.password
      }).then(response => {
        this.setCookie(cookieName, btoa(JSON.stringify(response.data.refreshToken)), 14)
        document.location = `${localStorage.getItem('referer')}`
      }).catch((error) => {
        if (error.response) {
          this.error = error.response.data.message
        } else {
          this.error = 'Could not connect to server. Please try again, or contact an administrator if the issue persists.'
        }
      }).finally(() => {
        this.sending = false
      });
    },
    checkFormValidity() {
      this.checkFieldValidity('usernameState', 'Username-input')
      this.checkFieldValidity('passwordState', 'password')
      return this.$refs.loginForm.checkValidity()
    },
    checkFieldValidity(state, fieldRef) {
      this[state] = this.$refs[fieldRef].checkValidity()
    },
    /**
     * The normal login style, eg. using googles auth0 api.
     */
    doGoogleLogin() {
      let state = btoa(localStorage.getItem('referer'));
      let client_id = '924504472651-6e6faqhe5i9ccjrnsft728dn4kmd2dhu.apps.googleusercontent.com';
      let auth_request_data = {
        client_id: client_id,
        response_type: 'code',
        scope: 'openid email profile',
        redirect_uri: `${process.env.VUE_APP_API_URL}/tokens/googleAuthCode`,
        state: state,
        access_type: 'offline'
      };
      this.getAuthEndpoint().then(auth_endpoint => {
        window.location = auth_endpoint + "?" +
            Object.keys(auth_request_data).map(key => key + '=' + auth_request_data[key]).join('&')
      });
    },
    getAuthEndpoint() {
      return new Promise((resolve, reject) => {
        axios.get('https://accounts.google.com/.well-known/openid-configuration')
            .then((response) => {
              resolve(response.data['authorization_endpoint']);
            })
            .catch(reject);
      })
    }
  },
  mounted() {
    const cookieName = process.env.VUE_APP_AUTH_COOKIE
    this.deleteCookie(cookieName)

    if (this.$route.query.referer) {
      localStorage.setItem('referer', atob(decodeURIComponent(this.$route.query.referer)))
    } else {
      localStorage.setItem('referer', `${process.env.VUE_APP_OWN_URL}/services`)
    }

    if (this.$route.query.error) {
      this.error = this.$route.query.error
      let query = Object.assign({}, this.$route.query);
      delete query.error;
      this.$router.replace({ query });
    }
  }
}
</script>

<style scoped>
.login-container {
  max-width: 384px;
  border: 1px solid #e5e5e5;
  padding: 1.75em 1.25em;
  border-radius: .375rem;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji !important;
}

.brand, .brand:hover, .brand:focus {
  outline: none;
  font-size: 20px;
}
</style>
