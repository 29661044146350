<template>
  <div class="limiter">
    <div class="container">
      <div class="wrapper">
        <div class="error_code">404</div>
        <div class="error_phrase">Not Found</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Error404'
}
</script>

<style scoped>
@font-face {
  font-family: Poppins-Regular;
  src: url('/fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: Poppins-Medium;
  src: url('/fonts/poppins/Poppins-Medium.ttf');
}

@font-face {
  font-family: Poppins-Bold;
  src: url('/fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url('/fonts/poppins/Poppins-SemiBold.ttf');
}

/** General styling */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}

body, html {
  height: 100%;
  font-family: Poppins-Regular, sans-serif;
}

/** Login styling */
.limiter {
  width: 100%;
  margin: 0 auto;
}

.container{
  width: 100% !important;
  max-width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #fff;
}

.wrapper {
  width: 390px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  padding: 50px 50px 50px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Fira Sans", Avenir, "Helvetica Neue", "Lucida Grande", sans-serif;;
}

.error_code {
  padding-right: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-right: solid gray 1px;
  font-size: 22px;
}

.error_phrase {
  padding-left: 15px;
  font-size: 13px;
}
</style>
